// stylelint-disable no-descending-specificity
.ui.menu.nav-menu {
  .right.menu + .right.menu {
    margin-left: 0 !important;
  }

  .item > .input input {
    border-color: $mischka;
  }

  @media (max-width: $sm-max) {
    .mobile-cart-header {
      min-height: 30px;
    }

    .nav-menu__logo-link {
      max-width: 190px;
      height: 65px;
      margin: 0 auto;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }

    > .menu {
      display: block !important;

      .nav-menu__dropdown {
        justify-content: center;
        padding: 25px 0;

        .text {
          font-size: 18px;
        }

        i {
          display: none;
        }
      }

      .right.menu {
        position: static;
      }
    }
  }
}

.header-area--personas-page {
  .nav-menu__dropdown--personas {
    @media (min-width: $lg-min) {
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $athens-grey;
        position: absolute;
        bottom: -1px;
        left: calc(50% - 10px);
        z-index: 1;
      }
    }
  }
}

.ui.menu,
.community-menu__menu.menu {
  .ui.dropdown {
    .text {
      font-size: 16px;
      font-weight: 600;
    }

    > .dropdown.icon {
      top: 2px;
      margin-left: 10px;
      font-size: 15px;
      color: $deep-cyan;
    }

    &.nav-menu__dropdown {
      .menu {
        > .item {
          padding: 0 !important;
        }

        a {
          display: block;
          padding: 0.8em 1.2em;
          color: $black;
          text-decoration: none;
          font-weight: 400;

          &.active {
            font-weight: 800;
          }
        }
      }
    }

    &.nav-menu__dropdown--active {
      .text {
        font-weight: 800;
        color: $black !important;
      }

      > .dropdown.icon {
        color: $black !important;
      }
    }

    .menu {
      border-radius: 0;
      border-color: $mischka !important;
      box-shadow: 0 2px 5px 0 rgba($mischka, 0.5);

      > .item {
        font-size: 16px !important;
      }
    }
  }

  &.nav-menu.nav-menu--small {
    @media (min-width: $md-min) {
      height: 66px;
    }

    .nav-menu__logo-link {
      img {
        @media (min-width: $md-min) {
          max-height: 50px !important;
        }
      }
    }
  }

  &.nav-menu {
    height: 66px;
  }
}

.nav-menu__search-submit {
  background-color: $light;
  border-radius: 0 4px 4px 0;
  width: 60px;
  height: 39px;
  border: 1px solid $mischka;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include transition(background-color);

  svg {
    width: 18px;
    height: 18px;
  }

  &:hover,
  &:focus {
    background-color: $athens-grey;
  }
}

.nav-menu__search {
  input {
    border-radius: 4px;

    @media (min-width: 1500px) {
      width: 250px;
    }

    &::placeholder {
      font-style: italic;
      color: $mischka;
    }
  }
}

.ui.action.input:not([class*='left action']) input:focus {
  border-right-color: transparent !important;

  &::placeholder {
    color: $mischka;
  }
}

.header-area {
  .hide-for-small {
    /* Hide on tablet and up */
    @media (min-width: $md-min) {
      display: none !important;
    }

    /* Show on tablet and up */
    @media (min-width: $sm-max) and (max-width: $md-max) {
      display: block !important;
    }
  }

  .nav-menu__logo-link,
  .dropdown.nav-menu__dropdown,
  .nav-menu__nav-link {
    /* Hide on tablet and up */
    @media (min-width: $sm-max) and (max-width: $md-min) {
      display: none !important;
    }
  }
}

.nav-menu__nav-link {
  @media (max-width: $md-max) {
    padding: 10px 0 !important;
  }
}

.context-menu__item {
  @media (max-width: $md-max) {
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
    padding-top: 7px;
  }

  .context-menu__item-text {
    @media (max-width: $md-max) {
      border-top: 1px solid #eee;
      padding-top: 7px;
    }
  }
}
