.user-settings__form {
  .user-settings__form-btns {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;

    .user-settings__form-submit {
      margin-left: 20px;
    }
  }
}
