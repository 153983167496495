.product-icons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  @media (max-width: $small-monitor-max) {
    padding: 10px 0;
    width: 100%;
  }

  @media (max-width: $mobile-max) {
    flex-flow: wrap;
    padding: 0;
  }
}

.product-icons__text {
  font-size: 10px;
  color: $light-text-grey;
  line-height: 10px;
  padding-top: 10px;
  text-align: center;
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.product-icons__icon-wrapper {
  margin: 0 5px;
  width: calc(100% / 2 - 10px);
  flex: 0 0 calc(100% / 2 - 10px);
  display: flex;
  flex-flow: column;
  align-items: center;

  @media (max-width: $mobile-max) {
    width: 33%;
    margin-bottom: 15px;
  }
}

.product-icons__icon {
  border-radius: 100% !important;
  width: 36px;
  height: 36px;
  padding: 0 !important;

  i {
    margin: 0 !important;
  }
}

.product-icons__icon--file {
  font-size: 13px !important;
  border-radius: 100px;
  background: $red;
  padding: 0 5px !important;
  width: 20px !important;
  height: 20px !important;
  color: $white;
}

.product-icons__icon--questions {
  background-color: $deep-cyan !important;
  font-size: 31px !important;
}

.product-icons__icon--sample {
  background-color: $button-grey !important;
  color: $deep-cyan !important;

  i {
    font-size: 26px !important;
    padding-top: 3px;
  }
}
