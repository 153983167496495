.checkout-form-and-details {
  margin: 0 !important;
}

.checkout-form-and-details__already-member {
  margin-left: 2px;

  @media (max-width: $sm-max) {
    margin-top: 25px;
  }
}

.checkout-form-and-details__outer-row {
  padding-top: 0 !important;
}

.checkout-form__read-only {
  input {
    color: $silver-chalice !important;
    border-color: $silver-chalice !important;
  }
}

.checkout-form-and-details__dropdown {
  width: 100%;
  margin: 0 0 20px;
  border-color: $silver-chalice !important;

  input {
    padding: 6px 12px;
    line-height: inherit;
  }
}

.checkout-form-and-details__checkout-form {
  input {
    &:read-only,
    &:focus,
    &:active {
      color: $silver-chalice !important;
      border-color: $silver-chalice !important;
    }

    &::placeholder {
      color: $silver-chalice !important;
    }
  }
}
