.product-sidebar {
  background-color: $button-grey;
  padding: 25px;

  h2 {
    font-weight: 500;
    text-transform: uppercase;
  }

  @media (min-width: $tablet-min) and (max-width: $small-monitor-max) {
    h2 {
      font-size: 20px;
    }
  }
}
