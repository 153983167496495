.header-items {
  display: flex;
  flex-flow: row nowrap;

  > :first-child {
    flex: calc(2 / 3 * 100%);
    width: calc(2 / 3 * 100%);
  }

  > :last-child {
    flex: calc(1 / 3 * 100%);
    width: calc(1 / 3 * 100%);
  }

  @media screen and (max-width: $small-monitor-max) {
    flex-flow: row wrap;
    justify-content: center;

    > :first-child,
    > :last-child {
      width: 100%;
      flex: 100%;
    }
  }
}
