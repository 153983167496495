// stylelint-disable no-descending-specificity
.tree-nav__menu-parent--collapsed {
  ul {
    display: none;
  }
}

.tree-nav-wrapper {
  background-color: $alabaster;
  padding: 25px;

  h2 {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 600;
  }

  .tree-nav {
    > .tree-nav__menu-parent {
      margin-bottom: 0;

      &.tree-nav__menu-parent--collapsed {
        span {
          border-top: 1px $silver solid;
          border-bottom: none;
          width: 100%;
          display: block;
          padding: 6px 0 3px 2px;
          text-transform: uppercase;

          > .caret {
            transform: rotate(00deg);
          }
        }
      }

      span {
        border-top: 1px $silver solid;
        border-bottom: 1px $silver solid;
        width: 100%;
        display: block;
        padding: 6px 0 3px 2px;
        text-transform: uppercase;

        > .caret {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 7px solid $mine-shaft;
          position: relative;
          right: 5px;
          top: 8px;
          float: right;
          transform: rotate(180deg);
        }
      }
    }

    // stylelint-disable no-duplicate-selectors
    > .tree-nav__menu-parent {
      .tree-nav__menu-parent--collapsed {
        span {
          > .caret {
            transform: rotate(00deg);
          }
        }
      }

      span {
        > .caret {
          width: 0;
          height: 0;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-top: 4px solid #a3a3a3;
          position: relative;
          right: 5px;
          top: 8px;
          float: right;
          transform: rotate(180deg);
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }
      }
    }
    // stylelint-enable no-duplicate-selectors

    > .tree-nav__menu-parent:last-of-type {
      span {
        border-bottom: 1px $silver solid;
      }
    }

    .tree-nav__menu-parent {
      .tree-nav__menu {
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }

    .tree-nav__menu {
      margin-bottom: 15px;
      margin-top: 10px;

      a {
        border-bottom: none;
        border-top: none;
        width: 100%;
        display: block;
        padding: 5px 0 0 4px;
        text-transform: none;
        font-size: 11px;
        line-height: 18px;
      }

      .tree-nav__menu-parent {
        margin-bottom: 0;

        span,
        a {
          border-bottom: none;
          border-top: none;
          width: 100%;
          display: block;
          padding: 3px 0 0 2px;
          text-transform: none;
          font-size: 11px;
          line-height: 18px;
        }

        .tree-nav__menu {
          margin-left: 20px;
          margin-bottom: 0;
          margin-top: 0;

          a {
            border-bottom: none;
            border-top: none;
            width: 100%;
            display: block;
            padding: 5px 0 0 2px;
            text-transform: none;
            font-size: 11px;
            line-height: 18px;
          }
        }
      }

      li {
        margin-bottom: 0;
      }
    }

    li {
      cursor: pointer;
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }

    a {
      color: #27251f;
      text-decoration: none;
      font-weight: inherit;
    }

    .active,
    .active a {
      color: #0f849e;
      text-decoration: none;
      font-weight: 600;
    }
  }
}
