.side-menu {
  @media (max-width: $sm-max) {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: -20px 0 !important;

    .side-menu__item--active {
      border-bottom: 1px solid #0f849e;
    }

    .side-menu__item {
      width: 50%;
      margin: 5px 0;

      .side-menu__link {
        margin: 0;

        .side-menu__item-icon {
          margin-right: 5px;
        }
      }
    }
  }
}
