/* Extra breakpoints */
$xl-max: 1399px;
$xl-min: 1400px;
$xl-slider-max: 1310px;

$extra-large-monitor-max: 1300px;
$large-monitor-min: 1200px;
$large-monitor-max: 1199px;
$small-monitor-min: 992px;
$small-monitor-max: 991px;
$tablet-min: 768px;
$tablet-max: 767px;
$mobile-min: 436px;
$mobile-max: 435px;

$persona-listing-wide: 1300px;

$os-small: 600px;

$row-reverse-small: 992px;

$rmr-blue: #00a3c7;
$light-text-grey: #969696;
$button-grey: #f3f3f3;
$header-grey: #27251f;
$white: #fff;
$back: #000;
$red: #f00;
$error-red: #eb1c26;
$success-green: #008000;
$silver: #bbb;
$mine-shaft: #333;
$alabaster: #f7f7f7;
$boulder: #767676;
$silver-chalice: #aaa;
