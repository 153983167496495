.newsletter-signup {
  margin: 10px 0 0;

  .message {
    margin: 10px 0;

    &.invalid {
      color: $error-red;
    }

    &.valid {
      color: $success-green;
    }
  }
}

.newsletter-signup__form {
  margin: 5px 0 0 !important;

  input {
    width: 100% !important;
  }

  .field {
    margin: 0 0 12px;

    label {
      color: $light-text-grey;
    }

    &.center {
      text-align: center;
    }
  }
}
