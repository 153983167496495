.password-settings__form {
  margin-top: 120px;

  .single-form {
    border: 1px solid #bbc4d1;
    padding: 50px 30px;

    input {
      border: 1px solid #0f849e !important;
      color: #0f849e !important;
    }
  }
}
