.banner-message {
  background-color: $rmr-blue;
  padding: 10px 0;
}

.banner-message__container {
  text-align: center;
  color: $white;

  > * {
    text-align: inherit;
  }
}

.banner-message__title {
  color: $white;
  font-weight: 600;
}
