.single-form {
  background-color: $white;
  margin-bottom: 30px;

  @media (min-width: 767px) {
    padding: 30px;
  }

  &.register {
    h2 {
      text-align: center;
    }
  }

  form.ui.form {
    .ui.input input {
      border: 1px solid #aaa;

      &:focus {
        border: 1px solid #0f849e;
      }
    }
  }
}

.field__non-interactive {
  padding: 0 0 0 12px;
  color: $cyan;
}

.field__non-interactive-label {
  padding: 0 0 0 12px;
  font-weight: bold;
  margin-bottom: 8px;
}

.field__non-interactive-input {
  padding: 0 0 0 12px;
  color: $deep-cyan;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-all;
}
// stylelint-disable no-descending-specificity
form.ui.form {
  margin: 25px 0;
}

.form__submit-button {
  margin-right: 10px !important;
}

.password-help-icon {
  padding-left: 5px;
}

.checkout-checkbox {
  label {
    float: left;
    padding-top: 6px !important;
    padding-right: 20px !important;
    padding-left: 0 !important;
  }

  .ui.input {
    width: auto !important;
    float: left !important;
  }
}

.organisation-form__submit {
  float: right;
  margin-top: 30px;
}

.payment__wrapper {
  padding: 20px;
  margin-bottom: 20px;
}
