.homepage-search {
  display: flex;
  flex-direction: column;
}

.homepage-search__wrapper {
  min-height: 390px;
  display: flex;
  align-items: center;
}

.homepage-search__input-container {
  width: 100%;
}

.homepage-search__search-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;

  @media (max-width: $os-small) {
    display: block !important;
    text-align: center;
    padding: 20px;
  }
}

.homepage-search__search-input {
  width: 100%;
  padding-right: 15px;

  input {
    border-radius: 5px !important;
  }

  input,
  .input {
    width: 100% !important;
  }

  @media (max-width: $os-small) {
    padding-right: 0;
  }
}

.homepage-search__search-button {
  @media (max-width: $os-small) {
    margin-top: 10px !important;
    width: 100%;
    max-width: 250px;
  }
}
