.product-search {
  margin-bottom: 20px;
}

.product-search__search-box {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
}

.product-search__search-input {
  width: 100%;
  display: flex;
  padding-right: 10px;

  input {
    height: 100% !important;
  }

  i {
    right: 10px !important;
  }
}

.product-search__search-button {
  width: 140px !important;
  font-size: 18px !important;
}

.product-search__filter-tag-wrapper {
  display: inline-block;
  padding-left: 10px;
}

.product-search__result-count {
  color: $silver;
  font-size: 16px;
}

.product-search__filter-tag {
  color: $silver !important;
  background-color: $alabaster !important;
}

.product-search__search-filter-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 360px) {
  .product-search__search-box {
    flex-flow: row wrap;
    justify-content: center;

    .product-search__search-input {
      margin-bottom: 20px;
    }
  }
}
