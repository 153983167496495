.price-and-controls {
  display: flex;
  align-items: center;
  flex-flow: column;
  padding: 0 5px;

  @media (max-width: $small-monitor-max) {
    padding: 10px 0 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
