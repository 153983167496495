.shopping-cart {
  display: flex;
  flex-flow: column;
}

.shopping-cart__item-row {
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  padding-bottom: 5px;

  @media (max-width: $small-monitor-max) {
    flex-flow: row wrap;
    padding-bottom: 15px;
  }

  @media (max-width: $tablet-min) {
    flex-flow: column;
  }

  &:last-of-type {
    padding-bottom: 0 !important;
  }
}

.shopping-cart__product-name-wrapper {
  flex: 1 1 auto;

  a {
    line-height: 17px;
    text-decoration: none;
    text-transform: capitalize;
  }
}

.shopping-cart__price-col {
  display: flex;
  flex-flow: row nowrap;
}

.shopping-cart__price-col-wrapper {
  margin-left: 5px;
  display: inline-block;

  strike {
    float: right;
  }

  @media (max-width: $small-monitor-max) {
    margin-left: 0;
  }
}

.shopping-cart__discount-amount {
  color: $error-red;
  float: right;
}

.shopping-cart__remove-item {
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-flow: column;

  &:hover {
    background: transparent;
    padding: 0;
    margin: 0;
    opacity: 0.7;
  }

  i {
    padding-top: 4px;
    color: $mine-shaft;
    margin: 0 !important;
  }
}

.shopping-cart__cart-items {
  background: $white;
  display: block;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 2px;

  ul {
    list-style: none;

    li {
      margin-left: 0;
      padding-left: 0;
    }
  }

  .row.cart-row {
    padding-top: 0;
    padding-bottom: 0;

    .wide.column.price-col {
      padding-top: 5px;
      padding-bottom: 5px;

      .price-label {
        font-size: 16px;
        color: #0f849e;
      }

      span {
        display: block;
        text-align: center;
      }

      button {
        padding: 5px;
        background-color: #eee !important;
        border: none;
        box-shadow: none !important;

        i {
          color: red;
        }
      }
    }

    .wide.column.product-name-col {
      padding-top: 5px;
      padding-bottom: 5px;

      a {
        display: block;
      }

      span {
        display: block;
      }
    }
  }
}

.cart-total-price {
  padding: 15px;
  background-color: #eee;
  color: #666;
  font-size: 16px;
  margin-top: 15px;
}

.checkout-cart {
  margin: 25px 0;

  .checkout-total-price {
    padding: 15px;
    margin: 0;
    background-color: #eee;
    font-size: 16px;
    margin-top: 15px;

    h2 {
      padding: 0;
    }

    .row {
      padding: 14px 0 0 0;

      .column {
        padding: 0;
      }
    }

    span.amount {
      width: 50%;
      float: right;
      text-align: right;
    }
  }

  .apply-coupon-form {
    margin: 0;
    display: flex;
    width: 100%;

    // @media (max-width: $tablet-max) {
    //   order: 2;
    // }

    .apply-coupon-container {
      display: block;
      width: 100%;
      border: 1px solid #aaa;
      margin: 20px 0 0 0;
      padding: 10px;

      h2 {
        padding: 0;
        margin: 0;
      }

      .noPaddingOrMargin.invalid {
        color: #eb1c26;
      }

      .noPaddingOrMargin.valid {
        color: #008000;
      }

      .row {
        padding-top: 2px;

        .column {
          padding: 0;

          .ui.input {
            width: 100%;

            input {
              width: 100%;
              margin-top: 5px;
              border: 1px solid #aaa;
            }
          }

          // stylelint-disable-next-line no-descending-specificity
          button {
            margin-top: 5px;
            float: right;
          }
        }
      }
    }
  }
}

.payment-fields {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 4px;

  .ui.radio {
    width: 50%;

    label {
      &::after {
        left: 0 !important;
      }
    }
  }

  .StripeElement {
    background: $white;
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
  }
}

.online-store__cart-header-items {
  background-color: $white !important;
}

.online-store__cart-header-icon.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
