.header-hero {
  width: 100%;
  height: 397px;
}

.header-hero.thank-you-hero {
  background-image: url(https://livecms.roymorganuat.com/wp-content/uploads/2017/11/baloons1.jpg);
  background-size: 100%;

  h1 {
    text-align: center;
    padding-top: 170px;
  }
}

.thank-you-content {
  padding-top: 50px;
}
